/*RR Farb CSS*/

/*Schriftfarbe*/

.RR_gelb_fc {
    color: #fce604;
}    

.RR_rot_fc {
    color: #e3000f;
}

.RR_blau_fc {
    color: #1e398F;
}

.RRA_blau_fc {
    color: #547282;
}

.RR_braun_fc {
    color: #742b20;
}

.RR_schwarz_fc {
    color: #1a1a18;
}

.RR_beige_fc {
    color: #aea97d;
}

.RRA_grau_fc {
  color: #edf1ed;
}

/*Hintergrundfarbe*/

.RR_gelb_bg {
    background-color: #fce604;
}    

.RR_rot_bg {
    background-color: #e3000f;
}

.RR_blau_bg {
    background-color: #1e398F;
}

.RRA_blau_bg {
    background-color: #547282;
}

.RR_braun_bg {
    background-color: #742b20;
}

.RR_schwarz_bg {
    background-color: #1a1a18;
}

.RR_beige_bg {
    background-color: #aea97d;
}

.RRA_grau_bg {
    background-color: #edf1ed;
}

.ContactImage {
    float: right;
    margin: 20px;
}